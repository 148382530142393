import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import headerStyle from "assets/jss/material-kit-react/components/headerStyle.jsx";
// images
import finwelogo_gray from "assets/img/finwe_logo_tinyres_gray.png";
import finwelogo_white from "assets/img/finwe_logo_tinyres_white.png";

// Cookie consent -> Google Analytics
import CookieConsent, { Cookies } from "react-cookie-consent";
import { globalHistory as history } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { Link } from "gatsby";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = typeof window !== 'undefined' && window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
      document
        .getElementById("logoImg").src = finwelogo_gray;
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      document
        .getElementById("logoImg").src = finwelogo_white;
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      typeof window !== 'undefined' && window.removeEventListener("scroll", this.headerColorChange);
    }
  }
  render() {
    const {
      classes,
      color,
      rightLinks,
      leftLinks,
      brand,
      fixed,
      absolute
    } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    });
    const brandComponent = 
      <Link className={classes.navLink} to="/">
        <img id="logoImg" src={finwelogo_white} alt={brand} width="100" />
      </Link>;
    /*
      <Button
      className={classes.title}
      href="/landing-page/"
      target="_self">
        <img id="logoImg" src={finwelogo_white} alt={brand} width="100" />
      </Button>;
    */

    const { location } = history;

    return (
      <AppBar className={appBarClasses}>
        <div>
          <CookieConsent
            //debug // Enable when testing, it will always show the consent query
            overlay="true"
            location="bottom"
            buttonText="OK, continue"
            enableDeclineButton
            declineButtonText="Minimal"
            setDeclineCookie
            buttonClasses="button"
            style={{ background: "#fffcf0", color: "#000000", padding: "15px", paddingBottom: "25px" }}
            buttonStyle={{ background: "#66bb66", color: "#ffffff", fontSize: "16px", padding: "15px", borderRadius: "12px" }}
            declineButtonStyle={{ background: "#cc4444", color: "#ffffff", fontSize: "16px", padding: "15px", borderRadius: "12px" }}
            cookieName="gatsby-gdpr-google-analytics"
            expires={365}
            onAccept={() => {
              // We must set the cookie manually, so that it exist before the next line.
              Cookies.set("gatsby-gdpr-google-analytics", true);

              // Now we can initialize tracking, which obeys cookie value.
              initializeAndTrack({ location });
            }}
          //onDecline={() => { alert("nay!") }}
          >
            <h3>Hey! Welcome to Finwe.</h3>
            <p>We use cookies to play videos, remember your preferences, filter spam,
                and to analyze our website traffic. <br /><br />

                You consent to all cookies by clicking "OK, continue",
                or to required only by clicking "Minimal". Notice that viewing videos
                requires all cookies.<br /><br />

                Read more from our <Link to="/md/legal/cookie-policy"> Cookies Policy </Link>
                and <Link to="/md/legal/privacy-policy"> Privacy Policy </Link> documents.
            </p>
          </CookieConsent>
        </div>
        <Toolbar className={classes.container}>
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden smDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
                brandComponent
              )}
          </div>
          <Hidden smDown implementation="css">
            {rightLinks}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.state.mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={this.handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is higher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default withStyles(headerStyle)(Header);
